import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { LoadingView } from '../common'
import JobCard from '../jobCard'
import { Tabs } from 'antd';
import "./index.scss";
import 'intro.js/introjs.css';
import { messaging } from "../login/firebase";
import { getToken } from 'firebase/messaging';
import LoginApi from '../../api/LoginApi';
import { postRequest } from '../../api/http';
// import 

export default function EmployerHome(props) {
  const { TabPane } = Tabs;
  const [loading] = useState(false)
  const [tabsKey, setTabsKey] = useState('PUBLISH');
  const [fcmDeviceToken, setFcmDeviceToken] = useState(null)
  useEffect(() => {
    fetchToken()
  }, [])
  const requestPermission = () => {
    console.log('user permisson...')

  }
  const fetchToken = async () => {
    //  requestPermission()
    Notification.requestPermission().then(permission => {
      if (permission === 'granted') {
        console.log('permssion granted')
        return getToken(messaging, {
          vapidKey: "BGR7U2k7c9k3uEpS0g5dWcspDs77A4LD-neGoDsBfNU9vhxS23O2CqCv8UION14ssc-kJJEnZTsTGvYhZsMWnqo"
        }).then(currentToken => {
          if (currentToken) {
            console.log('current', currentToken)
            setFcmDeviceToken(currentToken);
            localStorage.setItem("deviceToken", currentToken)
            const payload = {
              fcmDeviceToken: currentToken
            }
            const response =  postRequest(LoginApi.deviceToken(), payload)

            // alert(currentToken,'token')
          } else {
            console.log('failed to get regsitration tokem')
          }
        }).catch(err => {
          console.log('error occured', err)
        })
      } else {
        console.log('user permission denied')
      }
    })

  }

  
  //useEffect for tour guide
  // useEffect(() =>{
  //   // if(kycverified === false){
  //   const introjs = introJs();
  //   introjs.setOptions({
  //     steps: [
  //       {
  //       element: '#profilebtn',
  //       intro : 'Stand out with your brand - Showcase your logo on every interaction.',
  //     },
  //     {
  //       element: '#createjobstep',
  //       intro : 'Grab attention instantly - Attract candidates with a compelling job title.',
  //     },
  //     {
  //       element: '#home',
  //       intro : 'Active Opportunities - Explore your posted jobs that are live and ready for applications.',
  //     }
  //     ,
  //     {
  //       element: '#jobseekerstep',
  //       intro : 'Select Posted Jobs (Filter button): "Tailor your search - Find your ideal job among posted opportunities with ease."',
  //     }
  //     ,
  //     {
  //       element: '#published',
  //       intro : 'Active Opportunities - Explore your posted jobs that are live and ready for applications.s',
  //     }
  //     ,
  //     {
  //       element: '#onhold',
  //       intro : 'Temporarily Paused - Keep track of jobs currently on hold, ready to be reactivated',
  //     }
  //     ,
  //     {
  //       element: '#archive',
  //       intro : 'Job history at a glance - Access past filled positions and review applicants.',
  //     }
  //     ,
  //     {
  //       element: '#draft',
  //       intro : 'Work in Progress - Create and edit job posts before publishing them to the world.',
  //     }



  //   ],
  //   showStepNumbers : false,
  //   showProgress: false,
  //   showBullets: false,
  //     tooltipClass : 'customlayer',
  //     // dontShowAgain: true,

  //   }).start();
  // // }

  // }, [] );
  function onChangeTabs(key) {
    setTabsKey(key);
  }

  const renderJobsList = () => {
    if (loading) return <LoadingView message="Loading..." />;
    return (
      <JobCard tabkey={tabsKey} />
    );
  };

  return (
    <div className="container">
      <div id="profilebtn"></div>
      <div id="home"></div>
      <div id="jobseeker"></div>
      <div className="headingContainer">
        <div className="headingTitle"><h3>Find right Candidate in no time!</h3></div>
        <div className="headingButton">
          <Link className="btn ant-btn ant-btn-primary" to="/jobs/create-job" id="createjobstep">Create Job</Link>
        </div>
      </div>
      <div className="home">
        <div id="published"></div>
        <div id="onhold"></div>
        <div id="archive"></div>
        <div id="draft"></div>
        <Tabs defaultActiveKey="1" onChange={onChangeTabs}>
          <TabPane tab="Published Jobs" key="PUBLISH">
            {tabsKey === "PUBLISH" ? renderJobsList() : null}
          </TabPane>
          <TabPane tab="On Hold Jobs" key="ON_HOLD">
            {tabsKey === "ON_HOLD" ? renderJobsList() : null}
          </TabPane>
          <TabPane tab="Archived Jobs" key="ARCHIVE">
            {tabsKey === "ARCHIVE" ? renderJobsList() : null}
          </TabPane>
          <TabPane tab="Draft Jobs" key="DRAFT">
            {tabsKey === "DRAFT" ? renderJobsList() : null}
          </TabPane>
        </Tabs>
        {/* <div id="chatbtn"></div> */}
      </div>
    </div>
  );
}
